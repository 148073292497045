import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { IApplicationState } from "store";
import cx from "classnames";
import { format, utcToZonedTime, toDate } from "date-fns-tz";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TFunction, Trans, useTranslation } from "i18n";
import { RoxFlag } from "config/rox";
import {
  Head,
  Spinner,
  generateBreadcrumbsData,
  AddOnPurchaseModal,
  Pill,
} from "component-library";
import {
  AddOnEntitlements,
  PremiumEntitlements,
  MemberGroups,
} from "shared-types";
import { authorizationService } from "shared-auth";
import {
  cancelAddOn,
  getRecurlyDetails,
  IBillingApiResponse,
  MY_SUBSCRIPTION_QK,
  CANCEL_ADD_ON_MUTATION_KEY,
} from "services/api/BillingApi/BillingApi";
import { dataTestIds } from "data-testids";
import PageWrapperWithinLayout from "app-shell/PageWrapperWithinLayout/PageWrapperWithinLayout";
import applicationMap, { CheckoutType } from "services/applicationMap";
import { useNewAccSettingsPageAccessCheck } from "../AccountSettings/parts/hooks/useNewAccSettingsPageAccessCheck";
import SubscriptionCancelled from "pages/MySubscriptions/SubscriptionCancelled";
import CancellationModal from "components/Modals/CancellationModal";
import ReactivateModal from "components/Modals/ReactivateModal";
import CancelAddOnModal from "components/Modals/CancelAddOnModal";
import checkIsRenewalPeriod from "helpers/checkIsRenewalPeriod";
import { PlanCodePeriod } from "component-library/src/components/Organisms/Billing/types";
import { useUpdateDetailsPopUp } from "app/hooks/useUpdateDetailsPopUp";
import { useReturnUrl } from "shared-hooks";
import checkIsValidAddress from "helpers/checkIsValidAddress";
import {
  AddOnCalculatedStatus,
  BreadcrumbSection,
  SubscriptionCalculatedStatus,
  breadcrumbs,
} from "app/constants";
import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";
import { getRenewalLink } from "helpers/getRenewalLink";
import { AddOnCodes } from "component-library/src/components/Organisms/Billing/constants";
import { pushToDataLayer } from "app-shell/GoogleTagManager/GoogleTagManager";
import { AccountTypeBillingTypeMap } from "config/common";
import SettingSection from "components/SettingSection/SettingSection";
import BannerMessage from "components/BannerMessage";
import AddOnSection from "components/AddOnSection";
import { TRACKING_EVENT_TYPE } from "app-shell/GoogleTagManager/constants";
import TransferToGrads from "./TransferToGrads";

type GetSettingsSectionDataProps = {
  subscription?: IBillingApiResponse["subscription"];
  addOns?: IBillingApiResponse["addOns"];
  isSubscriptionCancelled: boolean;
  zonedDate: Date | string;
  isAllowedToSeeAdditionalServices: boolean;
  t: TFunction;
  onBuyAddOnClick: (code: AddOnCodes) => void;
  onCancelAddOnClick: () => void;
};

const DATE_FORMAT = "dd MMM yyyy";
const DATE_TIME_TZ_FORMAT = `${DATE_FORMAT} HH:mm zzz`;
const BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
const FM_ADD_ONS_PREFIX = "fm";

export const getStatusPillClassNames = (isActive: boolean) =>
  cx("self-start md:self-auto", {
    "bg-color-accent-one text-color-neutral-one": isActive,
    "bg-grey-light-2": !isActive,
  });

const getSettingsSectionData = ({
  subscription,
  addOns,
  isSubscriptionCancelled,
  zonedDate,
  isAllowedToSeeAdditionalServices,
  t,
  onBuyAddOnClick,
  onCancelAddOnClick,
}: GetSettingsSectionDataProps) => {
  const isSubscriptionActive =
    subscription?.state === AddOnCalculatedStatus.Active;
  const isEveryAddonAllowedToBuy = addOns?.every(
    (addOn) => addOn.availableToBuy
  );
  const isEveryAddonActive = addOns?.every(
    (addOn) => addOn.calculatedStatus === AddOnCalculatedStatus.Active
  );
  const isNotAllowedToBuyAddonsBannerHidden =
    !isAllowedToSeeAdditionalServices ||
    isEveryAddonAllowedToBuy ||
    isEveryAddonActive;

  return [
    {
      label: t("common:label.subscriptionType"),
      value: (
        <div className="flex flex-col gap-2 md:items-center md:flex-row">
          <span>{subscription?.plan ?? "-"}</span>
          {!!subscription?.plan && (
            <Pill
              size="md"
              theme="light"
              className={getStatusPillClassNames(isSubscriptionActive)}
            >
              {isSubscriptionActive
                ? t("common:label.active")
                : t("common:label.inactive")}
            </Pill>
          )}
        </div>
      ),
    },
    {
      label: t("common:label.subscriptionCurrentPeriod"),
      value: subscription?.currentTermStartedAt
        ? `${format(
            toDate(subscription?.currentTermStartedAt),
            DATE_FORMAT
          )} - ${
            subscription?.currentTermEndsAt &&
            format(toDate(subscription?.currentTermEndsAt), DATE_FORMAT)
          }`
        : "-",
    },
    {
      label: isSubscriptionCancelled
        ? t("common:label.subscriptionExpiresOn")
        : t("common:label.subscriptionRenewsExpiresOn"),
      value: zonedDate
        ? format(zonedDate as Date, DATE_TIME_TZ_FORMAT, {
            timeZone: BROWSER_TIMEZONE,
          })
        : "-",
    },
    {
      isHidden: !isAllowedToSeeAdditionalServices || addOns?.length === 0,
      label: t("common:label.additionalServices"),
      value: addOns?.map((addOn) => (
        <AddOnSection
          key={addOn.code}
          addOn={addOn}
          onCancelAddOnClick={onCancelAddOnClick}
          onBuyAddOnClick={onBuyAddOnClick}
        />
      )),
    },
    {
      isHidden: isNotAllowedToBuyAddonsBannerHidden,
      label: "",
      value: (
        <BannerMessage
          title={t("common:accountSettings.ecpAddon.banner.title")}
          description={
            <Trans
              i18nKey="common:accountSettings.ecpAddon.banner.description"
              components={{
                a: (
                  <a
                    href="https://support.spotlight.com/hc/en-us"
                    target="_blank"
                  />
                ),
              }}
            />
          }
        />
      ),
    },
  ];
};

const MySubscriptions = () => {
  const queryClient = useQueryClient();
  const [selectedAddon, setSelectedAddon] = useState<AddOnCodes>();
  const queryShouldNotBeLoaded = useNewAccSettingsPageAccessCheck(true);
  const { t } = useTranslation();
  const history = useHistory();
  const [cancellationModalIsOpen, setCancellationModalIsOpen] = useState(false);
  const [revertModalIsOpen, setRevertModalIsOpen] = useState(false);
  const [subscriptionSuccessCancelled, setSubscriptionSuccessCancelled] =
    useState(false);
  const [isCancelAddOnModalOpen, setIsCancelAddOnModalOpen] = useState(false);
  const { redirectWithReturnUrl } = useReturnUrl();
  const { isRoxLoaded, isNewAccSettingsAllowSubscriptionCancellationFlagOn } =
    useSelector((state: IApplicationState) => {
      return {
        isNewAccSettingsAllowSubscriptionCancellationFlagOn:
          state.rox.configuration?.[
            RoxFlag.NewAccSettings_AllowSubscriptionCancellation
          ].isEnabled() ?? false,
        isRoxLoaded: state.rox.isLoaded,
      };
    });

  const {
    AddOnCheckout: isAddOnCheckoutFlagEnabled,
    ShowAdditionalServicesSetting: isShowAdditionalServicesSettingEnabled,
    Grads2024Launch: isGrads2024LaunchEnabled,
  } = useLaunchDarklyFlags([
    FEATURE_FLAGS.ShowAdditionalServicesSetting,
    FEATURE_FLAGS.AddOnCheckout,
    FEATURE_FLAGS.Grads2024Launch,
  ]);

  // errors caught by boundary
  const {
    data: mySubscriptionData,
    isLoading,
    refetch,
  } = useQuery<IBillingApiResponse | undefined>({
    queryKey: MY_SUBSCRIPTION_QK,
    queryFn: async () => await getRecurlyDetails(),
    cacheTime: 0,
    enabled: !queryShouldNotBeLoaded,
  });
  const { mutate, isLoading: isCancelAddOnsLoading } = useMutation({
    mutationKey: CANCEL_ADD_ON_MUTATION_KEY,
    mutationFn: cancelAddOn,
    onSuccess: () => {
      setIsCancelAddOnModalOpen(false);
      queryClient.invalidateQueries(MY_SUBSCRIPTION_QK);
    },
  });

  const { subscription, address, addOns, account } = mySubscriptionData || {};

  const isValidAddress = checkIsValidAddress(address);
  const renewalLink = getRenewalLink(isAddOnCheckoutFlagEnabled, {
    planCode: subscription?.planCode,
  });
  const reactivationLink = applicationMap.routes.checkout({
    type: CheckoutType.reactivation,
  });
  const { showUpdateDetailsPopUp } = useUpdateDetailsPopUp(() =>
    redirectWithReturnUrl(
      applicationMap.routes.settingsMyDetails(),
      renewalLink
    )
  );

  // ADD ON SECTION CONDITIONS
  const isMainPerformer =
    authorizationService.getUserGroup() === MemberGroups.performer;
  const isUkPerformer = !!account?.isUkPerformer;
  const hasAddons = !!(addOns && addOns?.length > 0);
  const isAllowedToSeeAdditionalServices =
    isMainPerformer &&
    isShowAdditionalServicesSettingEnabled &&
    isUkPerformer &&
    hasAddons;

  const goToMyAccount = () =>
    history.replace(applicationMap.routes.accountSettings());

  const addOnSuccessCallback = (trackingData: any = {}) => {
    const userGroup = authorizationService.getUserGroup();

    pushToDataLayer({
      event: TRACKING_EVENT_TYPE.AddOnPurchase,
      memberType:
        AccountTypeBillingTypeMap[
          userGroup as keyof typeof AccountTypeBillingTypeMap
        ],
      ...trackingData,
    });
    refetch();
    setSelectedAddon(undefined);
  };

  if (queryShouldNotBeLoaded) return null;
  if (isLoading || !isRoxLoaded) return <Spinner />;
  if (subscriptionSuccessCancelled) return <SubscriptionCancelled />;

  const isPremierLEGACY = authorizationService.doesUserHaveEntitlements(
    PremiumEntitlements.Premium_Site_Access
  );
  const hasAddOnBundle = authorizationService.doesUserHaveEntitlements(
    AddOnEntitlements.AddOnBundle
  );
  const isEntitled = isPremierLEGACY || hasAddOnBundle;
  const isSubscriptionCancelled =
    subscription?.state === AddOnCalculatedStatus.Canceled;
  const isRenewalPeriod = checkIsRenewalPeriod({
    planCode: subscription?.planCode,
    calculatedStatus: subscription?.calculatedStatus,
  });
  const isExpired =
    subscription?.calculatedStatus === SubscriptionCalculatedStatus.Expired;

  const isClassicUser = !isEntitled;
  const isMonthlyPayingPremiumUser =
    isEntitled && subscription?.planCode.includes(PlanCodePeriod.Monthly);
  const isAnnuallyPayingPremiumRenewalPeriod =
    isEntitled &&
    isRenewalPeriod &&
    subscription?.planCode.includes(PlanCodePeriod.Annual);
  const isPrimaryButtonEnabled =
    isClassicUser ||
    isMonthlyPayingPremiumUser ||
    isAnnuallyPayingPremiumRenewalPeriod;

  const shouldShowTransferToGrads = isMainPerformer && isGrads2024LaunchEnabled;

  const generatePrimaryButtonLabel = () => {
    if (isEntitled) {
      return t("common:button.label.changePlan");
    }

    if (isExpired) {
      return t("common:button.label.reactivateSubscription");
    }

    if (isRenewalPeriod) {
      return t("common:button.label.renewSubscription");
    }

    return "";
  };

  const primaryButtonProps = {
    label: generatePrimaryButtonLabel(),
    isDisabled: !isPrimaryButtonEnabled,
    onClick: () => {
      if (!isValidAddress) {
        showUpdateDetailsPopUp();
        return;
      }

      history.push(isExpired ? reactivationLink : renewalLink);
    },
    isHidden: isAddOnCheckoutFlagEnabled && !isExpired && !isRenewalPeriod,
  };

  const secondaryButtonProps = {
    label: isSubscriptionCancelled
      ? t("common:button.label.keepMyMembership")
      : t("common:button.label.cancelSubscription"),
    onClick: isSubscriptionCancelled
      ? () => setRevertModalIsOpen(true)
      : () => setCancellationModalIsOpen(true),
    isHidden:
      isRoxLoaded && !isNewAccSettingsAllowSubscriptionCancellationFlagOn,
  };

  const zonedDate = subscription?.currentTermEndsAt
    ? utcToZonedTime(
        toDate(`${subscription?.currentTermEndsAt}`, {
          timeZone: BROWSER_TIMEZONE,
        }),
        BROWSER_TIMEZONE
      )
    : "";

  const addOnsToCancel =
    mySubscriptionData?.addOns
      ?.filter((addOn) => addOn.code.includes(FM_ADD_ONS_PREFIX))
      .map((filteredAddOn) => filteredAddOn.code) || [];

  return (
    <>
      <Head>
        <title data-testid={dataTestIds.userAccountApp}>
          {t("performer:accountSettings.mySubscription.header")}
        </title>
      </Head>
      <PageWrapperWithinLayout
        pageHeader={t("performer:accountSettings.mySubscription.header")}
        subFooter={{
          text: t("common:button.label.close"),
          onClick: goToMyAccount,
        }}
        breadcrumbs={generateBreadcrumbsData(breadcrumbs, [
          BreadcrumbSection.MyAccount,
          BreadcrumbSection.AccountSettings,
        ])}
      >
        <SettingSection
          sectionDescription={t(
            "performer:accountSettings.mySubscriptionPage.description"
          )}
          subSectionHeader={t(
            "common:accountSettings.mySubscription.subSection.title"
          )}
          sectionData={getSettingsSectionData({
            subscription,
            addOns,
            isSubscriptionCancelled,
            zonedDate,
            isAllowedToSeeAdditionalServices,
            t,
            onBuyAddOnClick: (code) => setSelectedAddon(code),
            onCancelAddOnClick: () => setIsCancelAddOnModalOpen(true),
          })}
          actionButtonLabel={primaryButtonProps.label}
          actionButtonOnClick={primaryButtonProps.onClick}
          actionButtonDisabled={primaryButtonProps.isDisabled}
          actionButtonHidden={primaryButtonProps.isHidden}
          secondaryButtonHidden={secondaryButtonProps.isHidden}
          secondaryButtonLabel={secondaryButtonProps.label}
          secondaryButtonOnClick={secondaryButtonProps.onClick}
          sectionAdditionalContent={
            shouldShowTransferToGrads ? <TransferToGrads /> : undefined
          }
        />
        <CancellationModal
          isOpen={cancellationModalIsOpen}
          isEntitledSubscriber={isEntitled}
          setSubscriptionSuccessCancelled={setSubscriptionSuccessCancelled}
          closePopUp={() => setCancellationModalIsOpen(false)}
        />
        <ReactivateModal
          isOpen={revertModalIsOpen}
          closePopUp={() => setRevertModalIsOpen(false)}
          onSuccessCallback={refetch}
        />
        <CancelAddOnModal
          isOpen={isCancelAddOnModalOpen}
          closePopUp={() => setIsCancelAddOnModalOpen(false)}
          isCancelAddOnLoading={isCancelAddOnsLoading}
          onSubmitCancelAddOn={() =>
            mutate({
              subscriptionId: mySubscriptionData?.subscription.id || "",
              addons: addOnsToCancel,
            })
          }
        />
        <AddOnPurchaseModal
          selectedAddOnCode={selectedAddon}
          closePopUp={() => setSelectedAddon(undefined)}
          onSuccessCallback={addOnSuccessCallback}
        />
      </PageWrapperWithinLayout>
    </>
  );
};

export default MySubscriptions;
