import { Button } from "component-library";
import { useTranslation } from "i18n";
import React from "react";
import { useHistory } from "react-router-dom";
import applicationMap from "services/applicationMap";

const TransferToGrads = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="w-full lg:w-[80%] mb-6">
      <div className="mb-2.5 flex items center justify-between md:flex-row flex-col">
        <h3 className="text-xxl">
          {t(
            "performer:accountSettings.mySubscriptionPage.transferToGrad.title"
          )}
        </h3>
        <Button
          type="secondary"
          text={t(
            "performer:accountSettings.mySubscriptionPage.transferToGrad.buttonTitle"
          )}
          className="w-auto mt-2 md:mt-0"
          size="small"
          onClick={() =>
            history.push(applicationMap.routes.graduatesTransferInformation())
          }
        />
      </div>
      <span className="whitespace-pre-line">
        {t(
          "performer:accountSettings.mySubscriptionPage.transferToGrad.description"
        )}
      </span>
    </div>
  );
};

export default TransferToGrads;
