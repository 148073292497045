import { User } from "oidc-client";

export enum FEATURE_FLAGS {
  HeritageAndEthnicity = "HeritageAndEthnicity",
  "HeritageAndEthnicity-BreakdownsAndJobFilters" = "HeritageAndEthnicity-BreakdownsAndJobFilters",
  ProfileMakeupDetails = "ProfileMakeupDetails",
  JoiningCDProcess = "JoiningCDProcess",
  AddOnCheckout = "AddOnCheckout",
  FilmmakersTrial = "FilmmakersTrial",
  ShowAdditionalServicesSetting = "ShowAdditionalServicesSetting",
  TagminAvailability = "TagminAvailability",
  FilmmakersSSO = "FilmmakersSSO",
  FilmmakersJobsEmails = "FilmmakersJobsEmails",
  DisabilityDiscountCheckbox = "DisabilityDiscountCheckbox",
  SelfTapes = "SelfTapes",
  AudioClipRequest = "AudioClipRequest",
  AgencySettings = "AgencySettings",
  RoleCast = "RoleCast",
  ECPModalVariant = "ECPModalVariant",
  GradsJoiningSignUp = "GradsJoiningSignUp",
  Grads2024Launch = "Grads2024Launch",
  ContinuousPaymentAuthorityAll = "ContinuousPaymentAuthorityAll",
}

export const buildUser = (thisUser: User | null | undefined) => {
  return thisUser
    ? {
        key: thisUser.profile.sub,
        name: thisUser.profile.name,
        email: thisUser.profile.email,
        anonymous: Object.keys(thisUser).length === 0,
      }
    : {
        key: "anonymous",
        name: "",
        email: "",
        anonymous: true,
      };
};
