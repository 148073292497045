declare const __CONFIG__: {
  locationsApiUrl: string;
  umbracoApiUrl: string;
  billingApiUrl: string;
  recurlyToken: string;
  profileApiUrl: string;
  profileApiRoot: string;
  facetsApiUrl: string;
  isDev?: boolean;
};

interface PhotoValidation {
  photoValidation: {
    acceptedFileTypes: string[];
    acceptedFileExtensions: string[];
    maxFileSize: number;
    minResolution: {
      width: number;
      height: number;
    };
  };
}

export const config: PhotoValidation & typeof __CONFIG__ = {
  ...__CONFIG__,
  photoValidation: {
    acceptedFileTypes: ["image/jpeg", "image/png", "image/bmp"],
    acceptedFileExtensions: [".jpg", ".jpeg", ".png", ".bmp"],
    maxFileSize: 40 * 1024 * 1024,
    minResolution: {
      width: 500,
      height: 500,
    },
  },
};
